import { render, staticRenderFns } from "./AddModifyRateModal.vue?vue&type=template&id=54b702e6&scoped=true"
import script from "./AddModifyRateModal.vue?vue&type=script&lang=js"
export * from "./AddModifyRateModal.vue?vue&type=script&lang=js"
import style0 from "./AddModifyRateModal.vue?vue&type=style&index=0&id=54b702e6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54b702e6",
  null
  
)

export default component.exports