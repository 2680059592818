var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('el-dialog', {
    attrs: {
      "visible": _vm.isModalVisible,
      "title": "Confirm Rate Deletion"
    },
    on: {
      "update:visible": function ($event) {
        _vm.isModalVisible = $event;
      },
      "close": _vm.closeModal
    }
  }, [_c('div', {
    staticClass: "main-content"
  }, [_c('h6', [_vm._v("Are you sure you want to delete the following rate:")]), _c('el-table', {
    attrs: {
      "data": [_vm.rate]
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "Amount",
      "width": "150"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.rate.amount))])]), _c('el-table-column', {
    attrs: {
      "label": "Rate",
      "width": "150"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.rate.rate))])]), _c('el-table-column', {
    attrs: {
      "label": "Promo Type",
      "width": "150"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.rate.is_promo == 1 ? "Expiry" : "Non-Expiry"))])]), _c('el-table-column', {
    attrs: {
      "label": "Description",
      "width": "150"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.rate.description))])]), _c('el-table-column', {
    attrs: {
      "label": "Expiry Period",
      "width": "150"
    }
  }, [_vm.rate.expiry_period == 0 ? _c('span', [_vm._v("Indefinite")]) : _vm._e(), _vm.rate.expiry_period == 1 ? _c('span', [_vm._v("Monthly Bundle")]) : _vm._e(), _vm.rate.expiry_period == 2 ? _c('span', [_vm._v("Quarterly Bundle")]) : _vm._e(), _vm.rate.expiry_period == 3 ? _c('span', [_vm._v("Half Year Bundle")]) : _vm._e()]), _c('el-table-column', {
    attrs: {
      "label": "User Type",
      "width": "120"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.rate.user_type_rate == 1 ? "Individual" : _vm.rate.user_type_rate == 2 ? "Corporate" : "Both") + " ")])]), _c('el-table-column', {
    attrs: {
      "label": "Service Type",
      "width": "150"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.rate.service_type == 1 ? "SMS" : "Voice"))])])], 1)], 1), _c('template', {
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "loading": _vm.deleteLoading,
      "type": "danger"
    },
    on: {
      "click": _vm.deleteRate
    }
  }, [_vm._v("Delete")])], 1)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };