import Storage from "../../../../services/storage";
var storage = new Storage();
export default {
  data() {
    return {
      rules: {
        amount: [{
          required: true,
          message: "Amount is required.",
          trigger: "blur"
        }],
        rate: [{
          required: true,
          message: "Enter a valid rate",
          trigger: "blur"
        }],
        is_promo: [{
          required: true,
          message: "Enter a valid rate type",
          trigger: "blur"
        }],
        service_type: [{
          required: true,
          message: "Enter a valid service type",
          trigger: "blur"
        }],
        country: [{
          required: true,
          message: "Enter a valid country",
          trigger: "blur"
        }],
        user_type_rate: [{
          required: true,
          message: "Enter a valid account type",
          trigger: "blur"
        }],
        description: [{
          required: false,
          message: "Enter a valid description",
          trigger: "blur"
        }]
      },
      dialogVisible: false,
      buttonloading: false,
      countries: [{
        value: "GH",
        label: "Ghana (GH)"
      }, {
        value: "CI",
        label: "Cote D'Ivoire (CI)"
      }, {
        value: "SN",
        label: "Senegal (SN)"
      }]
    };
  },
  props: {
    showAddModifyRate: {
      type: Boolean,
      required: true
    },
    formData: {
      type: Object,
      default: () => {
        return {
          amount: "",
          rate: "",
          is_promo: "",
          service_type: "",
          user_type_rate: "",
          description: "",
          expiry_period: "",
          country: ""
        };
      }
    }
  },
  computed: {
    isModalVisible: {
      get() {
        return this.showAddModifyRate;
      },
      set(value) {
        this.$emit("closeModal");
      }
    },
    disallowedUsers() {
      return this.$store.getters["auth/disallowedUsers"];
    },
    availableCountries() {
      return this.countries.filter(country => {
        // if disallowed user show only the country of the user
        if (this.disallowedUsers) {
          return country.value === storage.getSavedState("auth.admin").country;
        }
        return true;
      });
    }
  },
  methods: {
    closeAddModifyRate() {
      this.$emit("closeModal");
      return;
    },
    sendRates() {
      this.buttonloading = true;
      let payload = {
        amount: this.formData.amount,
        rate: this.formData.rate,
        is_promo: this.formData.is_promo,
        service_type: this.formData.service_type,
        user_type_rate: this.formData.user_type_rate,
        description: this.formData.description,
        expiry_period: this.formData.is_promo == 1 ? this.formData.expiry_period : 0,
        id: this.formData.id || null,
        country: this.formData.country
      };
      this.$store.dispatch("admin/rates/sendSMSVoiceRate", payload).then(response => {
        if (response.status === 200) {
          this.$notify({
            message: "Rate sent successfully",
            type: "success"
          });
          this.buttonloading = false;
          this.dialogVisible = false;
          this.$emit("closeModal");
        }
      }).catch(error => {
        this.$notify({
          message: "Error sending rate",
          type: "error"
        });
      }).finally(() => {
        this.buttonloading = false;
      });
    },
    openModal(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.dialogVisible = true;
        } else {
          return false;
        }
      });
    }
  }
};