import store from "@/state/store.js";
import { mapGetters } from "vuex";
import AddModifyRateModal from "./rates/AddModifyRateModal.vue";
import deleteRateModal from "./rates/deleteRateModal.vue";
import Storage from "../../../services/storage";
var storage = new Storage();
export default {
  components: {
    deleteRateModal,
    AddModifyRateModal
  },
  data() {
    return {
      formData: {
        amount: "",
        rate: "",
        is_promo: "",
        service_type: "",
        user_type_rate: "",
        description: "",
        expiry_period: "",
        country: ""
      },
      dialogVisible: false,
      buttonloading: false,
      tableLoading: false,
      page: 1,
      pageSize: 20,
      total: 0,
      showAddModifyRate: false,
      deleteRateContent: {},
      showDeleteRateModal: false,
      countries: [{
        value: "GH",
        label: "Ghana"
      }, {
        value: "CI",
        label: "Cote d'Ivoire"
      }, {
        value: "SN",
        label: "Senegal"
      }]
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
    },
    refetchRates(country) {
      this.tableLoading = true;
      store.dispatch("admin/rates/getSMSVoiceRate", {
        country: this.formData.country
      }).then(response => {
        if (response.status === 200) {
          this.tableLoading = false;
        }
      }).catch(error => {
        this.$notify({
          message: "There was an error fetching rates",
          type: "error"
        });
      });
    },
    openAddModifyRate() {
      this.showAddModifyRate = true;
      this.formData.amount = "";
      this.formData.rate = "";
      this.formData.is_promo = "";
      this.formData.service_type = "";
      this.formData.user_type_rate = "";
      this.formData.description = "";
      this.formData.expiry_period = "";
      this.formData.country = "";
    },
    editRate(scope) {
      this.formData.amount = scope.amount;
      this.formData.rate = scope.rate;
      this.formData.is_promo = scope.is_promo;
      this.formData.service_type = scope.service_type;
      this.formData.user_type_rate = scope.user_type_rate;
      this.formData.description = scope.description;
      this.formData.expiry_period = scope.expiry_period;
      this.formData.id = scope.id;
      this.showAddModifyRate = true;
      this.formData.country = scope.country;
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    filterHandlerCountry(value) {
      // refetch rates
      return this.refetchRates(value);
    },
    deleteRate(scope) {
      this.deleteRateContent = scope;
      this.showDeleteRateModal = true;
    },
    hideAddModifyRateModal() {
      this.showAddModifyRate = false;
      this.refetchRates();
    },
    hideDeleteModal() {
      this.showDeleteRateModal = false;
      this.deleteRateContent = {};
      this.refetchRates();
    }
  },
  mounted() {
    this.formData.country = storage.getSavedState("auth.admin").country || "GH";
    this.refetchRates();
  },
  computed: {
    ...mapGetters({
      rates: "admin/rates/getSMSVoiceRates"
    }),
    disallowedUsers() {
      return this.$store.getters["auth/disallowedUsers"];
    },
    availableCountries() {
      return this.countries.filter(country => {
        // if disallowed user show only the country of the user
        if (this.disallowedUsers) {
          return country.value === storage.getSavedState("auth.admin").country;
        }
        return true;
      });
    }
  },
  filters: {
    currency(row, key) {
      if (!row) return "";
      if (row.country == "CI") {
        // return amount formatted into CFA
        return row[key].toLocaleString("fr-FR", {
          style: "currency",
          currency: "CFA"
        });
      }
      return row[key].toLocaleString("en-US", {
        style: "currency",
        currency: "GHS"
      });
    }
  }
};