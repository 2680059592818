export default {
  data() {
    return {
      deleteLoading: false
    };
  },
  props: {
    rate: {
      type: Object,
      required: true
    },
    showDeleteModal: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isModalVisible: {
      get() {
        return this.showDeleteModal;
      },
      set(value) {
        this.$emit("closeModal");
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    deleteRate() {
      this.deleteLoading = true;
      if (this.$props.rate.hasOwnProperty("id")) {
        this.$store.dispatch("admin/rates/deleteRate", {
          id: this.$props.rate.id
        }).then(data => {
          this.$message({
            message: "Rate deleted successfully",
            type: "success"
          });
          this.$emit("closeModal");
        }).catch(error => {
          this.$message({
            message: error.response.data.message,
            type: "error"
          });
        }).finally(() => {
          this.deleteLoading = false;
        });
      }
    }
  }
};